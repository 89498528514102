import {Config} from "./config";
import {Dom} from "./dom";

let Data = null;

export class DataForm {

  static _mapQuerySelect(inputName) {
    return +document.querySelector(`input[name="${inputName}"]`).checked;
  }

  static parseInputMaskToFloat(value) {
    const getValue = value.replace(" ", "");
    return parseFloat(getValue.replace(/,/, "."));
  }

  static setData(){

    const getValue = inputName => document.getElementById(inputName).value;

    const czy_kwota_brutto = this._mapQuerySelect(
      Config.prefix + "czy_kwota_brutto"
    );
    const praca_poza_miejscem_zamieszkania = this._mapQuerySelect(
      Config.prefix + "praca_poza_miejscem_zamieszkania"
    );
    const kwota_wolna = this._mapQuerySelect("kwota_wolna");
    const mlody = this._mapQuerySelect("mlody");
    const fp = this._mapQuerySelect("fp");
    const os_32 = this._mapQuerySelect("os_32");
    const osw_pit_mlody = this._mapQuerySelect("osw_pit_mlody");
    const czy_fgsp = this._mapQuerySelect("czy_fgsp");
    const ppk = this._mapQuerySelect("ppk");

    const kwota = this.parseInputMaskToFloat(getValue(`${Config.prefix}kwota`));
    const ppk_procent_pracodawca = this.parseInputMaskToFloat(
      getValue(`${Config.prefix}ppk_procent_pracodawca`)
    );
    const ppk_procent_lacznie = this.parseInputMaskToFloat(
      getValue(`${Config.prefix}ppk_procent_lacznie`)
    );
    const procent_kosztow_autorskich = this.parseInputMaskToFloat(
      getValue(`${Config.prefix}procent_kosztow_autorskich`)
    );
    const procent_pracodawca_wypadkowe = this.parseInputMaskToFloat(
      getValue(`${Config.prefix}procent_pracodawca_wypadkowe`)
    );

    Data = {
      kwota,
      ppk_procent_pracodawca,
      ppk_procent_lacznie,
      procent_kosztow_autorskich,
      procent_pracodawca_wypadkowe,
      czy_kwota_brutto: czy_kwota_brutto === 0 ? 1 : 0,
      czy_mlody: mlody,
      czy_oswiadczenie_pobieranie_pit_mlody: osw_pit_mlody,
      czy_oswiadczenie_niepobieranie_drugi_prog: os_32,
      praca_poza_miejscem_zamieszkania: praca_poza_miejscem_zamieszkania,
      czy_kwota_wolna: kwota_wolna,
      czy_fp: 1,
      czy_fgsp: czy_fgsp,
      czy_ppk: ppk,
      kwoty_brutto: [],
      czy_fp_bez_limitu: fp
    };
  }


  static get data(){
    return Data;
  }


  static setMonthValue() {
    for (let i = 0; i <= 11; i++) {
      let monthValue = document.getElementById(
        `${Config.prefix}kwota_brutto_${i}`
      ).value;

      Data.kwoty_brutto.push({
        kwota_brutto: this.parseInputMaskToFloat(monthValue),
        numer_miesiaca: i + 1
      });

      monthValue = Dom.formatNumberWithComma(monthValue);
    }
  }


  static setDataRecalculate(){
    Data.czy_kwota_brutto = 1;
    Data.kwoty_brutto = [];
    Data.czy_fp_bez_limitu = 0;
    this.setMonthValue();
  }



}
