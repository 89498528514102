import { Dom } from "./dom";
import { Config } from "./config";

export class Table {
  static createTable(data) {
    let newColor = false;
    let pracodawca_skladka_fp_min_limit = true;

    data &&
      data.forEach((next, i) => {
        const idMiesiaca = i + 1;
        const miesiac = document.getElementById(idMiesiaca.toString());
        const input = document.getElementById(
          `${Config.prefix}kwota_brutto_${i.toString()}`
        );

        next.czy_zmieniono_prog ? (newColor = true) : null;
        newColor ? miesiac.classList.add(`${Config.prefix}new-color`) : null;

        const childeren = miesiac.children;

        if (!next.pracodawca_skladka_fp_min_limit){
          Dom.enableFpCheckbox();
          pracodawca_skladka_fp_min_limit =false
        }

        input.value = Dom.formatNumberWithComma(next.kwota_brutto);

        childeren[2].innerText = Dom.formatNumberWithComma(
          next.pracownik_skladka_emerytura
        );
        childeren[3].innerText = Dom.formatNumberWithComma(
          next.pracownik_skladka_rentowa
        );
        childeren[4].innerText = Dom.formatNumberWithComma(
          next.pracownik_stawka_chorobowa
        );
        childeren[5].innerText = Dom.formatNumberWithComma(
          next.pracownik_stawka_zdrowotna
        );

        childeren[6].innerText = Dom.formatNumberWithComma(
          next.pracownik_stawka_zdrowotna_odliczana
        );

        childeren[7].innerText = Dom.formatNumberWithComma(next.pit_zaliczka);
        childeren[8].innerText = Dom.formatNumberWithComma(
          next.pracownik_skladka_ppk
        );
        childeren[15].innerText = Dom.formatNumberWithComma(next.kwota_netto);

        childeren[9].innerText = Dom.formatNumberWithComma(
          next.pracodawca_skladka_emerytura
        );
        childeren[10].innerText = Dom.formatNumberWithComma(
          next.pracodawca_skladka_rentowa
        );
        childeren[11].innerText = Dom.formatNumberWithComma(
          next.pracodawca_skladka_wypadkowa
        );
        childeren[12].innerText = Dom.formatNumberWithComma(
          next.pracodawca_skladka_fp
        );
        childeren[13].innerText = Dom.formatNumberWithComma(
          next.pracodawca_skladka_fgsp
        );
        childeren[14].innerText = Dom.formatNumberWithComma(
          next.pracodawca_skladka_ppk
        );
        childeren[16].innerText = Dom.formatNumberWithComma(
          next.pracodawca_koszt_calkowity
        );
      });

    if ( pracodawca_skladka_fp_min_limit){
      Dom.disabledFpCheckbox();
    }
  }

  static createSummary(next) {
    const miesiac = document.getElementById("summary");
    const childeren = miesiac.children;

    childeren[1].innerText = Dom.formatNumberWithComma(next.suma_kwota_brutto);
    childeren[2].innerText = Dom.formatNumberWithComma(
      next.suma_pracownik_skladka_emerytura
    );
    childeren[3].innerText = Dom.formatNumberWithComma(
      next.suma_pracownik_skladka_rentowa
    );
    childeren[4].innerText = Dom.formatNumberWithComma(
      next.suma_pracownik_stawka_chorobowa
    );
    childeren[5].innerText = Dom.formatNumberWithComma(
      next.suma_pracownik_stawka_zdrowotna
    );

    childeren[6].innerText = Dom.formatNumberWithComma(
      next.suma_pracownik_stawka_zdrowotna_odliczana
    );
    childeren[7].innerText = Dom.formatNumberWithComma(next.suma_pit_zaliczka);
    childeren[8].innerText = Dom.formatNumberWithComma(
      next.suma_pracownik_skladka_ppk
    );

    childeren[9].innerText = Dom.formatNumberWithComma(next.suma_kwota_netto);

    childeren[10].innerText = Dom.formatNumberWithComma(
      next.suma_pracodawca_skladka_emerytura
    );
    childeren[11].innerText = Dom.formatNumberWithComma(
      next.suma_pracodawca_skladka_rentowa
    );
    childeren[12].innerText = Dom.formatNumberWithComma(
      next.suma_pracodawca_skladka_wypadkowa
    );
    childeren[13].innerText = Dom.formatNumberWithComma(
      next.suma_pracodawca_skladka_fp
    );
    childeren[14].innerText = Dom.formatNumberWithComma(
      next.suma_pracodawca_skladka_fgsp
    );
    childeren[15].innerText = Dom.formatNumberWithComma(
      next.suma_pracodawca_skladka_ppk
    );
    childeren[16].innerText = Dom.formatNumberWithComma(
      next.suma_pracodawca_koszt_calkowity
    );
  }
}
