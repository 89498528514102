import { Api } from "./api";
import { Config } from "./config";
import { DataForm } from "./data-form";

const switcher = document.querySelectorAll(`.${Config.prefix}switcher`);
const employeeClass = document.querySelectorAll(`.${Config.prefix}employee`);
const employerClass = document.querySelectorAll(`.${Config.prefix}employer`);
const errAll = document.querySelectorAll(`.${Config.prefix}err`);
const allInputTypeText = document.querySelectorAll(`input[type="text"]`);

const trAll = document.querySelectorAll("tr");
const tooltipAll = document.querySelectorAll(`.${Config.prefix}tooltip`);

const header_main = document.getElementById(`${Config.prefix}header_main`);
const employee = document.getElementById(`${Config.prefix}employee_switch`);
const employer = document.getElementById(`${Config.prefix}employer_switch`);
const result_header = document.getElementById(`${Config.prefix}result_header`);
const error = document.getElementById(`${Config.prefix}error`);
const errorIe = document.getElementById(`${Config.prefix}error_ie`);
const kwota = document.getElementById(`${Config.prefix}kwota`);
const fp_label = document.getElementById(`${Config.prefix}fp_label`);
const fp_checkbox = document.querySelector(`input[name="fp"]`);
const bruttoNetto_checkbox = document.querySelector(
  `input[name="${Config.prefix}czy_kwota_brutto"]`
);
const fp_span = document.getElementById(`${Config.prefix}czy_fp`);

export class Dom {
  static formatNumberWithComma(value) {
    let val = value.toString().replace(" ", "");
    val = val.replace(/[a-zA-Z]+/g, "");
    val = val.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    val = val.replace(".", ",");

    return val;
  }

  static addOnclickToTooltip() {
    tooltipAll.forEach(elem => {
      elem.onclick = function() {
        return true;
      };
    });
  }

  static maskForTextInput() {
    !Dom.isIE11() &&
      allInputTypeText &&
      allInputTypeText.forEach(elem => {
        elem.addEventListener("keyup", () => {
          elem.value = Dom.formatNumberWithComma(elem.value);
        });
      });
  }

  static disabledFpCheckbox() {
    fp_label.classList.add("ecv_calc_color-silver-tfoot");
    fp_checkbox.checked = true;
    fp_checkbox.disabled = true;
    fp_span.classList.add("ecv_calc_checkmark-dis");
    fp_span.classList.add("ecv_calc_bg-unset");
    fp_span.classList.remove("ecv_calc_checkmark");
  }

  static enableFpCheckbox() {
    fp_label.classList.remove("ecv_calc_color-silver-tfoot");
    fp_checkbox.checked = false;
    fp_checkbox.disabled = false;
    fp_span.classList.remove("ecv_calc_checkmark-dis");
    fp_span.classList.remove("ecv_calc_bg-unset");
    fp_span.classList.add("ecv_calc_checkmark");
  }

  static kwotaBruttoNettoClick() {
    bruttoNetto_checkbox.addEventListener("click", () => {
      !bruttoNetto_checkbox.checked ? this._manageFpCheckbox() : null;
    });
  }

  static _manageFpCheckbox() {
    Api.setMonth();
    DataForm.parseInputMaskToFloat(kwota.value) >= 2600
      ? this.disabledFpCheckbox()
      : this.enableFpCheckbox();

    kwota.value = Dom.formatNumberWithComma(kwota.value);
  }

  static changeKwota() {
    kwota &&
      kwota.addEventListener("keyup", () => {
        this._manageFpCheckbox();
      });
  }

  static unsetBackgroundZl() {
    kwota.style.background = "#fff !important";
  }

  static switchTableData() {
    switcher &&
      switcher.forEach(element => {
        element.addEventListener("click", function(e) {
          const eventUser = e.target.id;

          const changeClassActive = (add, remove) => {
            add.classList.add(`${Config.prefix}active`);
            remove.classList.remove(`${Config.prefix}active`);
          };

          const changeDataTable = (add, remove) => {
            add &&
              add.forEach(el => {
                el.classList.add(`${Config.prefix}d-none`);
              });

            remove &&
              remove.forEach(el => {
                el.classList.remove(`${Config.prefix}d-none`);
              });
          };

          eventUser === `${Config.prefix}employee_switch`
            ? changeClassActive(employee, employer)
            : changeClassActive(employer, employee);

          eventUser === `${Config.prefix}employee_switch`
            ? changeDataTable(employerClass, employeeClass)
            : changeDataTable(employeeClass, employerClass);
        });
      });
  }

  static isIE11() {
    return !!window.MSInputMethodContext && !!document.documentMode;
  }

  static clearErrAll() {
    errAll &&
      errAll.forEach(elem => {
        elem.classList.contains(`${Config.prefix}error-border`)
          ? elem.classList.remove(`${Config.prefix}error-border`)
          : null;

        elem.classList.contains(`${Config.prefix}error-text`)
          ? elem.classList.add(`${Config.prefix}d-none`)
          : null;
      });
  }

  static checkboxShowHidden(label, wrapper) {
    label &&
      label.addEventListener("change", () => {
        wrapper.classList.contains(`${Config.prefix}d-none`)
          ? wrapper.classList.remove(`${Config.prefix}d-none`)
          : wrapper.classList.add(`${Config.prefix}d-none`);
      });
  }

  static clearNewColorTr() {
    trAll &&
      trAll.forEach(elem => {
        elem.classList.contains(`${Config.prefix}new-color`)
          ? elem.classList.remove(`${Config.prefix}new-color`)
          : null;
      });
  }

  static showResult() {
    Dom.scrollToResult();
  }

  static setErrorMessage(msg) {
    if (this.isIE11()) {
      this.addErrorTextInfo(errorIe);
      errorIe.classList.add(`${Config.prefix}error-text`);
      errorIe.innerText = msg;
    } else {
      this.addErrorTextInfo(error);
      error.classList.add(`${Config.prefix}error-text`);
      error.innerText = msg;
    }
  }

  static clearErrorMessage() {
    this.isIE11() ? (errorIe.innerText = "") : (error.innerText = "");
  }

  static scrollToResult() {
    result_header.scrollIntoView({
      behavior: "smooth",
      inline: "start"
    });
  }

  static addErrorTextInfo(element) {
    !element.classList.contains(`${Config.prefix}error-text`)
      ? element.classList.add(`${Config.prefix}error-text`)
      : null;
  }

  static addErrorBorder(element) {
    element.classList.add(`${Config.prefix}error-border`);
  }

  static showElement(element) {
    element.classList.contains(`${Config.prefix}d-none`)
      ? element.classList.remove(`${Config.prefix}d-none`)
      : null;
  }

  static hiddenElement(element) {
    !element.classList.contains(`${Config.prefix}d-none`)
      ? element.classList.add(`${Config.prefix}d-none`)
      : null;
  }

  static setHeaderTextYear() {
    const year = new Date().getFullYear();
    header_main
      ? (header_main.innerText = `Kalkulator wynagrodzeń (płac) brutto - netto ${year}`)
      : null;
  }

  static changeLabelValue(czy_koszt_label) {
    czy_koszt_label &&
      czy_koszt_label.addEventListener("change", () => {
        const value = Api.mapQuerySelect("koszty_autorskie_checkbox");
        const koszt = document.getElementById("koszt_autorski");
        !value ? (koszt.value = 0) : null;
      });
  }
}
