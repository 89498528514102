import axios from "axios";
import { Table } from "./table";
import { Dom } from "./dom";
import { Config } from "./config";
import { DataForm } from "./data-form";

const BASE_URL = process.env.BASE_URL;
const btnSubmit = document.querySelectorAll(`.${Config.prefix}submit_btn`);
let setMonth = false;
export class Api {
  static setMonth() {
    setMonth = false;
  }

  static async runCalculate() {
    Dom.clearNewColorTr();
    Dom.clearErrAll();
    Dom.clearErrorMessage();
    try {
      await axios
        .post(`${BASE_URL}/calculate/`, DataForm.data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(function(response) {
          Table.createTable(response.data.koszty);
          Table.createSummary(response.data.podsumowanie);
          Dom.showResult();
        })
        .catch(function(error) {
          let msg = null;
          let fieldName = null;
          let element = null;

          if (error && error.response && error.response.data) {
            msg = error.response.data.error.message;
            fieldName = error.response.data.error.field;
            element = document.getElementById(Config.prefix + fieldName);
          }

          if (fieldName && element) {
            Dom.addErrorBorder(element);
            const textP = document.getElementById(
              `${Config.prefix}${fieldName}_error`
            );
            if (textP) {
              Dom.addErrorTextInfo(textP);
              Dom.showElement(textP);
              textP.innerText = msg;
            }
          } else {
            msg = msg ? msg : "Wystąpił nie oczekiwany błąd";
            Dom.setErrorMessage(msg);
          }
        });
    } catch (e) {
      console.error(e);
    }
  }

  static calculate() {
    btnSubmit &&
      btnSubmit.forEach(btn => {
        btn.addEventListener("click", event => {
          event.preventDefault();
          this.setData();
          const main = async () => {
            await Api.runCalculate();
          };

          main();
        });
      });
  }

  static recalculate() {
    for (let i = 0; i <= 11; i++) {
      const month = document.getElementById(
        `${Config.prefix}kwota_brutto_${i}`
      );
      month &&
        month.addEventListener("change", () => {
          this.setData();
          document.querySelector(
            `input[name="${Config.prefix}czy_kwota_brutto"]`
          ).checked = 0;
          DataForm.setDataRecalculate();
          setMonth = true;

          const runSearch = async () => {
            await Api.runCalculate();
          };
          runSearch();
        });
    }
  }

  static setData() {
    DataForm.setData();
    setMonth ? DataForm.setMonthValue() : null;
  }
}
