import "babel-polyfill";


if (process.env.APP === 'PIT'){
  require( "./scss/pit.scss");
}else {
  require( "./scss/bankier.scss");
}

import { Dom } from "./js/dom";
import { Api } from "./js/api";
import {Config} from "./js/config";

const ppk_label = document.getElementById(`${Config.prefix}ppk_label`);
const czy_mlody_label = document.getElementById(`${Config.prefix}czy_mlody_label`);
const oswiadczenie_26 = document.getElementById(`${Config.prefix}oswiadczenie_26`);
const czy_koszt_label = document.getElementById(`${Config.prefix}czy_koszt_label`);
const col_3 = document.getElementById(`${Config.prefix}col-3`);
const btnIe = document.getElementById(`${Config.prefix}btn-ie`);
const ie = document.getElementById(`${Config.prefix}ie`);
const ppk_wrapper = document.getElementById(`${Config.prefix}ppk_wrapper`);
const autorskie_wrapper = document.getElementById(`${Config.prefix}autorskie_wrapper`);

(function() {
  if (typeof NodeList.prototype.forEach === "function") return false;
  NodeList.prototype.forEach = Array.prototype.forEach;
})();

const ifIe11 = () => {
  (ie.innerText = "Twoja przeglądarka może nie obsługiwać wszystkich funkcji. Zalecana zmiana przeglądarki.");
  Dom.showElement(btnIe);
  Dom.hiddenElement(col_3);
  Dom.unsetBackgroundZl();
};
Dom.kwotaBruttoNettoClick();
Dom.addOnclickToTooltip();
Dom.isIE11()
  ? ifIe11()
  : null;

Dom.maskForTextInput();
Dom.setHeaderTextYear();
Dom.checkboxShowHidden(ppk_label, ppk_wrapper);
Dom.checkboxShowHidden(czy_koszt_label, autorskie_wrapper);
Dom.checkboxShowHidden(czy_mlody_label, oswiadczenie_26);
Dom.switchTableData();
Dom.changeLabelValue(czy_koszt_label);
Dom.changeKwota();

Api.calculate();
Api.recalculate();
